import './App.css';

function App() {
  return (
    <div
      className="App"
      style={{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'row', // Side-by-side on larger screens
      }}
    >
      <div
        className="hero-section"
        style={{
          flex: 1,
          textAlign: 'center',
          padding: '50px',
          backgroundColor: '#00c3ff', // Light blue background to match brand colors
          color: '#fff',
        }}
      >
        <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>Welcome to VeeFriends Cartoons!</h1>
        <p style={{ fontSize: '1.2rem', margin: '20px 0' }}>
          Join the fun world of <strong>VeeFriends Cartoons</strong>! Watch as our favorite characters face thrilling challenges, learn valuable lessons, and promote positivity. Perfect for kids and families who love fun, educational cartoons!
        </p>
        
        {/* Embed YouTube Videos with larger size and design around */}
        <div className="video-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
          <div
            style={{
              margin: '20px',
              padding: '10px',
              border: '5px solid #ff4500', // Border color to match the brand
              borderRadius: '10px',
              boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)', // Adding a shadow for some depth
              backgroundColor: '#fff',
              maxWidth: '800px', // Limit the max width to make it more visually balanced
            }}
          >
            <iframe
              width="700"
              height="394"
              src="https://www.youtube.com/embed/URLrtu_T3Wc"
              title="YouTube video 1"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ borderRadius: '5px' }}
            ></iframe>
          </div>
          
          <div
            style={{
              margin: '20px',
              padding: '10px',
              border: '5px solid #ff4500', // Border color to match the brand
              borderRadius: '10px',
              boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)', // Adding a shadow for some depth
              backgroundColor: '#fff',
              maxWidth: '800px', // Limit the max width to make it more visually balanced
            }}
          >
            <iframe
              width="700"
              height="394"
              src="https://www.youtube.com/embed/xV_ldpase50"
              title="YouTube video 2"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ borderRadius: '5px' }}
            ></iframe>
          </div>
          
          <div
            style={{
              margin: '20px',
              padding: '10px',
              border: '5px solid #ff4500', // Border color to match the brand
              borderRadius: '10px',
              boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)', // Adding a shadow for some depth
              backgroundColor: '#fff',
              maxWidth: '800px', // Limit the max width to make it more visually balanced
            }}
          >
            <iframe
              width="700"
              height="394"
              src="https://www.youtube.com/embed/SKwBrtUbREE"
              title="YouTube video 3"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ borderRadius: '5px' }}
            ></iframe>
          </div>
        </div>

      

        <a
          href="https://www.youtube.com/@VeeFriendsCartoons?sub_confirmation=1"
          target="_blank"
          rel="noopener noreferrer"
          className="subscribe-button"
          style={{
            display: 'inline-block',
            padding: '12px 24px',
            backgroundColor: '#ff4500', // Bright orange for contrast and to match brand colors
            color: '#fff',
            fontWeight: 'bold',
            textDecoration: 'none',
            borderRadius: '5px',
            marginTop: '20px',
            fontSize: '1.1rem',
          }}
        >
          Subscribe for Weekly Adventures!
        </a>
      </div>

      <div
        className="domain-info"
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f3f3f3', // Light grey background for contrast
          color: '#333',
          padding: '50px',
        }}
      >
        <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '10px' }}>
          This domain, Veefriends Cartoons is available for sale
        </h2>
        <h3 style={{ fontSize: '1.1rem' }}>
          Please contact{' '}
          <a href="mailto:therealyab@gmail.com" style={{ color: '#ff4500', textDecoration: 'none' }}>
            therealyab@gmail.com
          </a>{' '}
          for quotes
        </h3>
      </div>

      <style>
        {`
          @media (max-width: 768px) {
            .App {
              flex-direction: column; /* Stack sections vertically on mobile */
            }
            .hero-section, .domain-info {
              padding: 30px; /* Adjust padding for smaller screens */
            }
            .video-container iframe {
              width: 100%;
              height: auto;
              max-width: 100%; /* Make video responsive */
            }
          }
        `}
      </style>
    </div>
  );
}

export default App;
